import React from 'react'
import { Center, Layout } from '../layout'
import Seo from '../seo'
import { SiteMetadata } from '../../interfaces/siteMetadata'
import { Location } from '../../interfaces/gatsby'

interface Props {
  en: boolean
  location: Location
  siteMetadata: SiteMetadata
}

export const Profile = ({ en, siteMetadata, location }: Props) => {
  return (
    <Layout en={en} location={location} siteMetadata={siteMetadata}>
      <Seo lang={en ? 'en' : 'ja'} />
      <Center>
        <div />
      </Center>
    </Layout>
  )
}
