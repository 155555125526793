import React from 'react'
import { useSiteMetadata } from '../hooks/useSiteMetadata'
import { Profile } from '../components/pages/profile'

const ProfilePage = (props: any) => {
  const siteMetadata = useSiteMetadata()
  const en = props.location.pathname.split('/')[1] === 'en'

  return <Profile {...props} en={en} siteMetadata={siteMetadata} />
}

export default ProfilePage
